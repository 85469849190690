jQuery(document).ready(function($) {

    if(typeof perPage === "undefined") {
        return;
    }
    let category = '';
    let currentPage = 1;

    if ($('.posts-container .post-wrapper').length < perPage) {
        $('.load-more').hide();
    }

    function load_posts(category, page) {
        const container = $('.posts-container');
        if(category) {
            container.addClass('loading');
        }
        $.ajax({
            url: ajaxUrl,
            data: {
                action: 'load_posts',
                category: category,
                page: page
            },
            type: 'POST',
            success: function(response) {
                const posts = $(response).find('.post');
                if (response === '' || posts.length < perPage) {
                    $('.load-more').hide();
                    container.append(response);
                } else {
                    container.append(response);
                    currentPage++;
                    $('.load-more').show();
                }
                container.removeClass('loading');
            },
            error: function(xhr, status, error) {
                console.log(error);
            }
        });
    }

    $('.category-filter').on('click', function() {
        let new_category = $(this).data('category');
        if ($(this).hasClass('active')) {
            category = '';
            currentPage = 1;
            $('.posts-container').empty();
            $('.category-filter').removeClass('active');
            load_posts(category, 1);
        } else {
            category = new_category;
            currentPage = 1;
            $('.posts-container').empty();
            $('.category-filter').removeClass('active');
            $(this).addClass('active');
            load_posts(category, 1);
        }
    });

    $(document).on('click', '.load-more', function() {
        load_posts(category, currentPage + 1);
    });
});
